import React, {useState} from "react";
import Center from "../../components/Center/Center";
import Navigation from "../../components/Demo1/Navigation.js";
//import Scrollspy from "react-scrollspy"; //npm i @makotot/ghostui instalar eso en su lugar
//al parecer es mejor con npm install --save react-ui-scrollspy
import ScrollSpy from "react-ui-scrollspy";
import useScrollPosition from "../../components/hooks/ScrollPosition";
import dacoffeImage from '../../utils/img/DACOFFE.png';
import './Productos.css';

function ComponenteProducts ({icon, imagen,titulo, texto}){
  return(
    <div className="card m-3" style={{width: '18rem', minHeight: '30rem'}}>
        <div className="icono">
          <img src={require(`../../utils/img/${icon}.png`)} alt="..." className="icon-bebida" />
        </div>
        <img src={require(`../../utils/img/${imagen}.png`)} className="card-img-top" alt="..."/>
        <div className="card-body">
          <h4 className="card-title">{titulo}</h4>
          <p className="card-text">{texto}</p>
        </div>
    </div>
  );
}
function Productos() {
  const [navVisible, setNavVisible] = useState(false);

  // Usar el hook para manejar la visibilidad de la navegación basado en la posición del scroll
  useScrollPosition(setNavVisible, 90); // Ajusta 100 a la altura en píxeles que desees

  return (
    <>
      <div className={`scrollspy ${navVisible ? "visible" : "hidden"}`}>
        <Navigation />
      </div>
      <ScrollSpy scrollThrottle={100} useBoxMethod={false}>
        <Center id="orange" backgroundColor={""} className="scroll-section">
          <section className="mt-3">
            <h1 className="text-center titulo-static">Bebidas <i className="bi bi-cup-straw"></i></h1>
            <div className="container-fluid carta-bebida">
              <div className="row justify-content-center">
                {/* <div className="card m-3" style={{width: '18rem', minHeight: '30rem'}}>
                  <div className="icono">
                    <img src={IconBebida} alt="..." className="icon-bebida" />
                  </div>
                  <img src={Cafe} className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h4 className="card-title">Cafe Expreso</h4>
                    <p className="card-text">Un verdadero deleite para los amantes del café, contiene simplemente café molido finamente y agua caliente, rica en sabor y aroma.</p>
                  </div>
              </div> */}
                <ComponenteProducts
                  icon="bebida"
                  imagen="12"
                  titulo="Cafe Expreso"
                  texto="Un verdadero deleite para los amantes del café, contiene simplemente café molido finamente y agua caliente, rica en sabor y aroma."
                />
                
                <ComponenteProducts
                  icon="bebida"
                  imagen="12"
                  titulo="Cafe Americano"
                  texto="Es una bebida simple y reconfortante compuesta por café negro y agua caliente ¡Una opción clásica para los amantes del café!"
                />
                <ComponenteProducts
                  icon="bebida"
                  imagen="12"
                  titulo="Cafe Olleta"
                  texto="Forma típica de preparar el café, aromatizarlo con canela, chocolate amargo, anís estrellado, pimienta de Tabasco y piel de naranja o limón."
                />
                <ComponenteProducts
                  icon="bebida"
                  imagen="12"
                  titulo="Cafe Capuchino"
                  texto="Contiene principalmente espresso, leche vaporizada y espuma de leche. A veces se añade un toque de cacao o canela en polvo encima de la espuma para decorar."
                />
                <ComponenteProducts
                  icon="29"
                  imagen="29"
                  titulo="Frappé de Fresa"
                  texto="Elaborada principalmente con fresas frescas o puré de fresa, hielo triturado, leche o helado, y ocasionalmente azúcar o jarabe para endulzar."
                />
                <ComponenteProducts
                  icon="29"
                  imagen="29"
                  titulo="Frappé de Mocca"
                  texto="Combina café instantáneo con sabor a chocolate, leche, hielo triturado y a veces crema batida. Es una opción popular para los amantes del café."
                />
                <ComponenteProducts
                  icon="29"
                  imagen="29"
                  titulo="Frappé de Mango"
                  texto="Es una bebida tropical y refrescante que combina mango fresco o puré de mango con hielo triturado, leche o yogurt, y a veces se añade azúcar o jarabe para endulzar."
                />
                <ComponenteProducts
                  icon="29"
                  imagen="29"
                  titulo="Frappé de Oreo"
                  texto="Es una deliciosa bebida helada que combina galletas Oreo trituradas, leche, helado de vainilla o crema, hielo y a veces crema batida."
                />
                <ComponenteProducts
                  icon="30"
                  imagen="30"
                  titulo="Jugo de Papaya"
                  texto="Es una refrescante bebida natural elaborada a partir de pulpa de papaya fresca, que se mezcla con agua o se licua para obtener una consistencia suave."
                />
                <ComponenteProducts
                  icon="30"
                  imagen="30"
                  titulo="Jugo de Mango"
                  texto="Reconocido por su contenido de vitamina C, antioxidantes y otros nutrientes, el jugo de mango no solo es refrescante, sino también nutritivo."
                />
                <ComponenteProducts
                  icon="30"
                  imagen="30"
                  titulo="J. Fresa con leche"
                  texto="La combinación de fresas y leche ofrece un sabor dulce y cremoso, con el toque refrescante y la acidez natural de las fresas."
                />
                <ComponenteProducts
                  icon="30"
                  imagen="30"
                  titulo="J. Plátano con leche"
                  texto="Es una opción nutritiva, rica en potasio, vitaminas y nutrientes esenciales, perfecta para disfrutar como un refrigerio o desayuno delicioso y energizante."
                />
              </div>
            </div>
          </section>
        </Center>
        <Center id="brown" backgroundColor={""} className="scroll-section">
          <section className="mt-3">
              <h1 className="text-center titulo-static">Alimentos <i className="bi bi-egg-fried"></i></h1>
            <div className="container-fluid carta-alimento">
              <div className="row justify-content-center">
                {/* <div className="card m-3" style={{width: '18rem', minHeight: '32rem'}}>
                  <div className="icono">
                    <img src={IconBebida} alt="..." className="icon-bebida" />
                  </div>
                  <img src={Alimento} className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h4 className="card-title">Tamal con Queso</h4>
                    <p className="card-text">El tamal con queso es una opción deliciosa y reconfortante, perfecta para disfrutar como parte de un desayuno, merienda o como plato principal en una comida típica.</p>
                  </div>
                </div> */}
                <ComponenteProducts
                  icon="23"
                  imagen="23"
                  titulo="Tamal con Queso"
                  texto="El tamal con queso es una opción deliciosa y reconfortante, perfecta para disfrutar como parte de un desayuno, merienda o como plato principal en una comida típica."
                />
                <ComponenteProducts
                  icon="24"
                  imagen="24"
                  titulo="Humitas con Queso"
                  texto="Esta delicia se destaca por su sabor suave y dulce, proveniente del maíz fresco, y por el contraste entre la masa tierna y el queso que lo acompaña."
                />
                <ComponenteProducts
                  icon="25"
                  imagen="25"
                  titulo="Tortillas con Queso"
                  texto="Son un aperitivo popular, un acompañamiento sabroso o incluso un plato principal, siendo muy apreciadas por su sencillez y su agradable combinación de sabores."
                />
                <ComponenteProducts
                  icon="26"
                  imagen="26"
                  titulo="Tortillas Asadas"
                  texto="Este método resalta el sabor natural de las tortillas, se disfrutan por su versatilidad y su agradable aroma y sabor ahumado que realza cualquier plato en el que se utilicen."
                />
              </div>
            </div>
          </section>
        </Center>
        <Center id="blue" backgroundColor={""} className="scroll-section">
        <section className="mt-3">
            <h1 className="text-center titulo-static">Postres <i className="bi bi-cake"></i></h1>
            <div className="container-fluid carta-postre">
              <div className="row justify-content-center">
                {/* <div className="card m-3" style={{width: '18rem', minHeight: '32rem'}}>
                  <div className="icono">
                    <img src={IconBebida} alt="..." className="icon-bebida" />
                  </div>
                  <img src={Alimento} className="card-img-top" alt="..."/>
                  <div className="card-body">
                    <h4 className="card-title">Pastel de Chocolate</h4>
                    <p className="card-text">Es un postre clásico y tentador. El pastel de chocolate es un placer indulgente y clásico, apreciado por su rico sabor a cacao y su textura suave y esponjosa.3</p>
                  </div>
              </div> */}
                <ComponenteProducts
                  icon="31"
                  imagen="31"
                  titulo="Pastel de Chocolate"
                  texto="Es un postre clásico y tentador. El pastel de chocolate es un placer indulgente y clásico, apreciado por su rico sabor a cacao y su textura suave y esponjosa."
                />
                <ComponenteProducts
                  icon="17"
                  imagen="17"
                  titulo="Pastel de Naranja"
                  texto="El pastel de naranja es conocido por su sabor cítrico y fresco, brindando una combinación encantadora de dulzura y acidez agradable."
                />
                <ComponenteProducts
                  icon="18"
                  imagen="18"
                  titulo="Empanadas"
                  texto="Consisten en una masa rellena que se puede hornear u freír, mientras que los rellenos son muy variados, desde opciones saladas con carne, pollo, pescado o verduras."
                />
                <ComponenteProducts
                  icon="19"
                  imagen="19"
                  titulo="Mousse de Café"
                  texto="Es un postre cremoso y ligero elaborado a partir de café, azúcar, yemas de huevo y crema batida. El resultado es un postre suave y aireado con un distintivo sabor a café."
                />
                <ComponenteProducts
                  icon="20"
                  imagen="20"
                  titulo="Crepe"
                  texto="Son deliciosas y finas tortillas, pueden estar rellenos con una variedad de sabores deliciosos, como fresa, mango, plátano, así como chocolate, miel, mermeladas y frutas frescas."
                />
                <ComponenteProducts
                  icon="21"
                  imagen="21"
                  titulo="Waffles"
                  texto="Los wafles son una delicia crujiente y esponjosa, hecha con una masa a base de harina, huevos, leche, mantequilla y polvo de hornear."
                />
                <ComponenteProducts
                  icon="22"
                  imagen="22"
                  titulo="Ensalada de Frutas"
                  texto="Los wafles son una delicia crujiente y esponjosa, hecha con una masa a base de harina, huevos, leche, mantequilla y polvo de hornear."
                />
              </div>
            </div>
          </section>
        </Center>
      </ScrollSpy>
      <div className="container text-center p-3 mt-5 mb-3">
            <div className="row mt-5 custom-bg">
                <div className="col-12 col-md-9 d-flex align-items-center">
                    <h1>Estas y más delicias puedes disfrutar acercándote a nuestro local</h1>
                </div>
                <div className="col-12 col-md-3">
                    <img className="imagen-s" src={dacoffeImage} alt="Imagen" style={{objectFit: 'cover'}}/>
                </div>
            </div>
        </div>
    </>
  );
}

export default Productos;
/*<div class="card m-3" style={{width: '18rem'}}>
    <img src={Taza} class="card-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    </div>
</div>*/
/*
<div>
          <h4 class="text-center">BEBIDAS</h4>
          <div class="row justify-content-center">
             <div class="card" style={{width: '18rem'}}>
                <img src="img/bebidas.jpeg" class="card-img-top" alt="..."/>
                <div class="card-body">
                   <h5 class="card-title">Card title</h5>
                   <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                   <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
             </div>
             <div class="card" style={{width: '18rem'}}>
                <img src="img/bebidas.jpeg" class="card-img-top" alt="..."/>
                <div class="card-body">
                   <h5 class="card-title">Card title</h5>
                   <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                   <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
             </div>
             <div class="card" style={{width: '18rem'}}>
                <img src="img/bebidas.jpeg" class="card-img-top" alt="..."/>
                <div class="card-body">
                   <h5 class="card-title">Card title</h5>
                   <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                   <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
             </div>
          </div>
          </div>
*/