import React from "react";

const Navigation = () => {
  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    
    <div className="scrollspy">
      <div className="navbar justify-content-center">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link txtscroll" href="#orange" onClick={(e) => onPress(e)}>Bebidas</a>
          </li>
          <li className="nav-item">
          <a className="nav-link txtscroll" href="#brown" onClick={(e) => onPress(e)}>Alimentos</a>
          </li>
          <li className="nav-item">
          <a className="nav-link txtscroll" href="#blue" onClick={(e) => onPress(e)}>Postres</a>
          </li>
        </ul>
      </div>
    </div>
    
  );
};

export default Navigation;
/* 
<div classNameName="navbar">
        <a href="#orange" onClick={(e) => onPress(e)} classNameName="nav-item">
          <div data-to-scrollspy-id="orange" classNameName="nav-link txtcroll">
            orange
          </div>
        </a>
        <a href="#brown" onClick={(e) => onPress(e)}>
          <div data-to-scrollspy-id="brown" classNameName={"ss-item"}>
            brown
          </div>
        </a>
        <a href="#blue" onClick={(e) => onPress(e)}>
          <div data-to-scrollspy-id="blue" classNameName={"ss-item"}>
            blue
          </div>
        </a>
        <a href="#green" onClick={(e) => onPress(e)}>
          <div data-to-scrollspy-id="green" classNameName={"ss-item"}>
            green
          </div>
        </a>
      </div>
*/