import React from "react";
import './Inicio.css';


export function MiniComponenteProductos ({imagen1,imagen2,imagen3,imagen4}) {
    return (
        <div className="galeria mb-2">
            <img src={require(`../../utils/img/${imagen1}.jpg`)} alt="Imagen" />
            <img src={require(`../../utils/img/${imagen2}.jpg`)} alt="Imagen" />
            <img src={require(`../../utils/img/${imagen3}.jpg`)} alt="Imagen" />
            <img src={require(`../../utils/img/${imagen4}.jpg`)} alt="Imagen" />
        </div>
    )
}
function Inicio (){
    return(
        <>
        <MiniComponenteProductos
            imagen1="001"
            imagen2="003"
            imagen3="005"
            imagen4="006"
        />
        <section className="mt-3 mx-md-5 mx-3 mb-2 rounded-5 seccion-uno">
            <div className="container align-items-center d-flex" style={{height:'350px'}}>
                <h1 className="text-center">"Bienvenidos a Dulce Aroma, donde cada taza de café cuenta una historia."</h1>
            </div>
        </section>
        <section>
            <div className="mt-3 mx-md-5 mx-3 mb-2">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner rounded">
                        <div className="carousel-item active">
                            <img src={require(`../../utils/img/Frase1_1.png`)} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={require(`../../utils/img/Frase2.png`)} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={require(`../../utils/img/Frase3.png`)} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
        <section className="mt-3 mx-md-5 mx-3 mb-2 rounded-5 bg-info seccion-dos">
            {/* Corazones */}
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="heart"></div>
            <div className="container d-flex justify-content-center align-items-center position-relative" style={{ height: '350px' }}>
                <div className="row w-100">
                <div className="col-12 col-md-4 d-flex justify-content-center"></div> {/* Columna vacía para el espaciado */}
                <div className="col-12 col-md-4 d-flex justify-content-center">
                    <div className="fondo-frase text-center">
                    <h1 className="text-center">NO HAY CAFÉ, NO SE TRABAJA</h1>
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center"></div> {/* Columna vacía para el espaciado */}
                </div>
            </div>
        </section>
        </>
    );
}

export default Inicio;