import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; /*importamos los estilos de bootstrap*/
import 'bootstrap-icons/font/bootstrap-icons.css'; /*importamos los íconos de bootstrap*/
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; /*importamos el js de bootstrap*/
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
