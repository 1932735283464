import { useEffect } from "react";

const useScrollPosition = (setNavVisible, threshold) => {
    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY >= threshold) {
            setNavVisible(true);
        } else {
            setNavVisible(false);
        }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, [setNavVisible, threshold]);
};

export default useScrollPosition;
