import React from "react";

const Center = ({
  children,
  backgroundColor = "#0EE17B",
  id,
  height = "",
  className = "",
}) => {
  const styles = {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    height: height,
    backgroundColor: backgroundColor,
  };

  return (
    <div id={id} style={styles} className={`container-fluid ${className}`}>
      {children}
    </div>
  );
};

export default Center;
