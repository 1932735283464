import React , { useState, useEffect }from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import './App.css';
import TazaCafe from './utils/img/TazaCafe.png';
import { Inicio, Productos, Nosotros } from './pages/rutas';

document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
});
document.addEventListener('selectstart', function(e) {
    e.preventDefault();
});

function LoadingScreen() {
    return (
        <div className="loading-screen">
            <img src={TazaCafe} alt="Logo Dulce Aroma" width="100" height="100" />
            <h1>Dulce Aroma</h1>
            <p>Cargando...</p>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
}

function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000); // 5 segundos

        return () => clearTimeout(timer); // Limpiar el timer en caso de que el componente se desmonte
    }, []);

    if (isLoading) {
        return <LoadingScreen />;
    }
    return (
        <Router>
            <a href="https://api.whatsapp.com/send/?phone=51971020368" className="btn-wsp" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-whatsapp"></i>
            </a>
            <div className="grad">
                <nav className="navbar navbar-expand-lg bg-light border-nav">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img src={TazaCafe} alt="..." width="50" height="50" />
                            DULCE AROMA
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link fw-bold" to="/inicio">Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link fw-bold" to="/productos">Productos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link fw-bold" to="/nosotros">Sobre Nosotros</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <Routes>
                    <Route path="/" element={<Navigate to="/inicio" />} />
                    <Route path='/inicio' element={<Inicio />} />
                    <Route path='/productos' element={<Productos />} />
                    <Route path='/nosotros' element={<Nosotros />} />
                </Routes>

                <footer className="footer">
                    <div className="card text-center">
                        <div className="container-fluid">
                            <div className="row p-5 pb-2 bg-select text-white">
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <p className="h3">Cafeteria</p>
                                    <p className="h3">Dulce Aroma</p>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <p className="h5 mb-3">Dirección</p>
                                    <div className="mb-2">
                                        <p className="text-ligth text-decoration-none"> Calle San Martin N°20441, Huancabamba-Piura-Perú</p>
                                    </div>
                                    <div className="mb-2">
                                        <a className="text-ligth text-decoration-none" href="https://maps.app.goo.gl/pbVvPTmEs3WASwj87" target="_blank" rel="noopener noreferrer">Estoy aquí <i className="bi bi-geo-alt"></i></a>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <p className="h5 mb-3">Contacto y atención al cliente</p>
                                    <div className="mb-2">
                                        <p className="text-ligth text-decoration-none" >Teléfono Piura: +51********</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="text-ligth text-decoration-none" >Teléfono Provincia: +51********</p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6 col-lg-3">
                                    <p className="h5 mb-3">Nuestras Redes</p>
                                    <div className="mb-2">
                                        <a className="btn btn-primary" href="https://web.facebook.com/profile.php?id=100087144188930" target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook" aria-hidden="true"><i className="bi bi-facebook"></i></i>
                                        </a>
                                    </div>
                                    <div className="mb-2">
                                        <a className="btn btn-dark" href="https://www.tiktok.com/@dulce.aroma.cafet0?_t=8oap7gmdRaP&_r=1" target="_blank" rel="noopener noreferrer">
                                            <i className="bi bi-tiktok icon-tiktok"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xs-12 pt-3">
                                    <p className="text-ligth text-center">&copy; 2023 Dulce Aroma. Todos los derechos reservados.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </Router>
    );
}

export default App;
