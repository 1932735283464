import React from "react";
import './Nosotros.css';

function ComponenteValores({imagen, texto}){
    return(
        <div className="col-md-4 col-lg-4 d-flex justify-content-center mb-5">
            <div className="card">
                <div className="row g-0">
                    <div className="col-4 imagen-contenido">
                        <img className="imagen-puesta" src={require(`../../utils/img/${imagen}.png`)} alt="imagen" />
                    </div>
                    <div className="col-8 d-flex align-items-center">
                        <div className="card-body">
                            <p className="card-text">{texto}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ComponenteEjemplo ({imagen, texto}) {
    return (
        <section className="seccion-Ejemplo">
            <div className="row">
                <div className="col-md-4 imagen">
                <img src={require(`../../utils/img/${imagen}.png`)} alt="Imagen" />
                </div>
                <div className="col-md-8 d-flex align-items-center">
                    <p className="text-justify"> {texto} </p>
                </div>
            </div>
        </section>
    )
}

function Nosotros (){
    return(
        <>
            <section>
                <div className="container">
                    <div className="sobrenst">
                        <h3 className="text-center mt-3">Sobre Dulce Aroma</h3>
                    </div>
                    <button className="btn btn-color w-100 mt-3 "><h2 className="txt-menu">Nuestra Historia</h2></button>
                    <div className="content mt-3">
                        <ComponenteEjemplo
                            imagen="008"
                            texto="En el corazón de Huancabamba, provincia de Piura, más conocida como la ciudad que camina, se encuentra la Cafetería Dulce Aroma. Este local acogedor y pintoresco ofrece una amplia variedad de opciones para aquellos que buscan disfrutar de un delicioso café y un desayuno satisfactorio. Aunque las opiniones de los clientes son mixtas, esta cafetería es conocida por sus precios razonables y su ambiente acogedor."
                        />
                    </div>
                    <button className="btn btn-color w-100 mt-3 "><h2 className="txt-menu">Nuestra Misión</h2></button>
                    <div className="content mt-3">
                        <ComponenteEjemplo
                            imagen="010"
                            texto="Crear un espacio acogedor donde cada taza de café sea una experiencia única. Queremos que nuestros clientes disfruten de aromas cautivadores, sabores excepcionales y momentos inolvidables. Nos esforzamos por ser más que una simple cafetería; somos un lugar donde las sonrisas se comparten y las conversaciones fluyen, todo mientras se deleitan con el mejor café"
                        />
                    </div>
                    <button className="btn btn-color w-100 mt-3 mb-3"><h2 className="txt-menu">Nuestra Visión</h2></button>
                    <div className="content mt-3">
                        <ComponenteEjemplo
                            imagen="011"
                            texto="Ser reconocidos como el destino preferido tanto por los locales como por los turistas en Huancabamba, ofreciendo una experiencia culinaria excepcional que resalte los sabores y la hospitalidad de nuestra ciudad. Queremos que cada visita a Dulce Aroma sea recordada por su calidez, calidad y el placer de compartir momentos especiales."
                        />
                    </div>
                </div>
            </section>
            {/* Seccion Valores */}
            <section className="container-fluid seccion-valores">
                <div className="mt-3 mb-5">
                    <h3 className="text-center">Nuestros Valores</h3>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <ComponenteValores
                            imagen = "007"
                            texto = "Ofrecemos café fresco y de alta calidad para asegurar un sabor excepcional"
                        />
                        <ComponenteValores
                            imagen = "007"
                            texto = "Nuestro espacio está diseñado para ser acogedor y confortable"
                        />
                        <ComponenteValores
                            imagen = "007"
                            texto = "Tenemos una amplia selección de cafés, tés y opciones sin cafeína"
                        />
                        <ComponenteValores
                            imagen = "007"
                            texto = "Nos enorgullecemos de nuestro servicio amable y atento"
                        />
                        <ComponenteValores
                            imagen = "007"
                            texto = "Complementamos nuestras bebidas con pasteles, bocadillos frescos y opciones saludables"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Nosotros;